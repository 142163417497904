import { Suspense, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useOrientation } from "@uidotdev/usehooks";

import settings from "./settings.json";
import "./App.css";

import Video from "./components/Video/Video";
import drehen from "./assets/img/Drehen.svg";
import Laden from "./assets/img/Laden.svg";

function App() {
  const handle = useFullScreenHandle();
  const videoRef = useRef(null);
  const orientation = useOrientation();

  const [home, setHome] = useState(true);
  const [loaded, setLoaded] = useState(0);
  const [buffering, setBuffering] = useState(false);

  //const [chapter, setChapter] = useState(-1);

  useEffect(() => {
    //    handle.enter();
    // window.addEventListener("load", function () {
    //   setTimeout(function () {
    //     window.scrollTo(0, 1);
    //   }, 0);
    // });
    // let scroll = window.setInterval(function () {
    //   window.scrollTo(0, 1);
    // }, 4000);
    //indow.scrollTo(0, 0);
  }, []);

  const startChapter = (c) => {
    videoRef.current.goToChapter(c);
    try {
      if (!handle.active) handle.enter();
      console.log("enter fullscreen", handle);
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => setHome(false), 500);
  };

  const checkOrientation = () => {
    return (
      orientation.type.toLocaleLowerCase().includes("landscape") ||
      orientation.angle === 90 ||
      orientation.angle === 270
    );
  };

  const handleBuffer = (b) => {
    console.log("Buffer", b);
    if (b) {
      setBuffering(false);
    } else {
      setBuffering(true);
    }
  };

  return (
    <FullScreen handle={handle}>
      <div className="container">
        {/* <span style={{ color: "hotpink", zIndex: 1000, position: "absolute" }}>
        orientation.type: {orientation.type} <br />
        orientation.angle: {orientation.angle} <br />
        check: {checkOrientation() ? "landscape" : "portrait"}
      </span> */}
        <div className={`App ${!checkOrientation() ? " turn" : ""}`}>
          {checkOrientation() && (
            <div className={`startPage${home ? "" : " out"}`}>
              <h1>{settings?.startHeadline}</h1>
              <p>{settings?.start}</p>
              <div className={`chapterButtons${loaded < 1 ? "" : " show"}`}>
                {settings?.menu?.map((c, i) => (
                  <div key={i}>
                    <button
                      className={`chapter chapter${i + 1}`}
                      onClick={() => {
                        startChapter(c.chapter);
                      }}
                    ></button>
                    {c.headline}
                  </div>
                ))}
              </div>
              <div className={`loading${loaded < 1 ? " show" : ""}`}>
                <img src={Laden} alt="Laden…" />
                <p>Laden…</p>
              </div>

              <div className="Legal">
                <a
                  href="https://www.ldbv.bayern.de/impressum.html"
                  target="_blank"
                >
                  Impressum
                </a>
                &nbsp;
                {" | "}
                &nbsp;
                <a
                  href="https://www.ldbv.bayern.de/datenschutz.html"
                  target="_blank"
                >
                  Datenschutz
                </a>
              </div>
            </div>
          )}
          {!checkOrientation() && (
            <div className={`turnPage`}>
              <h1>Die Reihenmesskamera</h1>
              <div className="turnInfo">
                <img className="drehImg" src={drehen} alt="Drehen" />
                <h1>
                  Bitte drehen Sie Ihr
                  <br />
                  Gerät ins Querformat
                </h1>
                <p>
                  Und stellen Sie sicher, dass die Ausrichtungssperre für Ihren
                  Bildschirm deaktiviert ist.
                </p>
              </div>
            </div>
          )}

          <Video
            out={home}
            src={settings.file}
            chapters={settings.chapters}
            ref={videoRef}
            setHome={setHome}
            handleLoad={(percent) => {
              //console.log("Video ready");
              console.log("Video loading", percent * 100, "%");
              setLoaded(percent);
            }}
            handleBuffer={handleBuffer}
          />
        </div>
      </div>
    </FullScreen>
  );
}

export default App;
